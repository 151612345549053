import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { useWeb3React } from '@web3-react/core'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import { METANO_MAINNET } from 'constants/tokens'
// import { SupportedChainId } from 'constants/chains'
// import { useTokensFlag } from 'featureFlags/flags/tokens'
// import { darken } from 'polished'
// import { NavLink, useLocation } from 'react-router-dom'
import { Text } from 'rebass'
// import { useToggleSelfClaimModal } from 'state/application/hooks'
// import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useCurrencyBalance, useNativeCurrencyBalances } from 'state/connection/hooks'
// import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
// import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { addTokenToMetamask } from 'utils/addToMetamask'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

// import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
// import { ExternalLink } from '../../theme'
// import ClaimModal from '../claim/ClaimModal'
// import { CardNoise } from '../earn/styled'
// import Menu from '../Menu'
// import Row from '../Row'
// import { Dots } from '../swap/styleds'
import Web3Status from '../Web3Status'
// import HolidayOrnament from './HolidayOrnament'
import NetworkSelector from './NetworkSelector'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: end;
  align-items: end;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem 8rem 1rem 1rem;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.deprecated_bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.deprecated_bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  gap: 0.5rem;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToLarge`
    grid-template-columns: 48px 1fr 1fr;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    padding:  1rem;
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    padding:  1rem;
    grid-template-columns: 36px;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safaris lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    align-items: center;
  `};
`

// const HeaderLinks = styled(Row)`
//   justify-self: center;
//   background-color: ${({ theme }) => theme.deprecated_bg0};
//   width: max-content;
//   padding: 2px;
//   border-radius: 16px;
//   display: grid;
//   grid-auto-flow: column;
//   grid-gap: 10px;
//   overflow: auto;
//   align-items: center;
//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToLarge`
//     justify-self: start;
//     `};
//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
//     justify-self: center;
//   `};
//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
//     flex-direction: row;
//     justify-content: space-between;
//     justify-self: center;
//     z-index: 99;
//     position: fixed;
//     bottom: 0; right: 50%;
//     transform: translate(50%,-50%);
//     margin: 0 auto;
//     background-color: ${({ theme }) => theme.deprecated_bg0};
//     border: 1px solid ${({ theme }) => theme.deprecated_bg2};
//     box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
//   `};
// `

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.deprecated_bg0 : theme.deprecated_bg0)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.deprecated_bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #00e1b7 0%, #007157 100%), #edeef2;
  cursor: pointer;
`

// const UNIWrapper = styled.span`
//   width: fit-content;
//   position: relative;
//   cursor: pointer;

//   :hover {
//     opacity: 0.8;
//   }

//   :active {
//     opacity: 0.9;
//   }
// `

const BalanceText = styled(Text)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    display: none;
  `};
`

// const Title = styled.a`
//   display: flex;
//   align-items: center;
//   pointer-events: auto;
//   justify-self: flex-start;
//   margin-right: 12px;
//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
//     justify-self: center;
//   `};
//   :hover {
//     cursor: pointer;
//   }
// `

// const UniIcon = styled.div`
//   transition: transform 0.3s ease;
//   :hover {
//     transform: rotate(-5deg);
//   }

//   position: relative;
// `

// can't be customized under react-router-dom v6
// so we have to persist to the default one, i.e., .active
// const activeClassName = 'active'

// const StyledNavLink = styled(NavLink)`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.deprecated_text2};
//   font-size: 1rem;
//   font-weight: 500;
//   padding: 8px 12px;
//   word-break: break-word;
//   overflow: hidden;
//   white-space: nowrap;
//   &.${activeClassName} {
//     border-radius: 14px;
//     font-weight: 600;
//     justify-content: center;
//     color: ${({ theme }) => theme.deprecated_text1};
//     background-color: ${({ theme }) => theme.deprecated_bg1};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
//   }
// `

// const StyledExternalLink = styled(ExternalLink)`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.deprecated_text2};
//   font-size: 1rem;
//   width: fit-content;
//   margin: 0 12px;
//   font-weight: 500;

//   &.${activeClassName} {
//     border-radius: 14px;
//     font-weight: 600;
//     color: ${({ theme }) => theme.deprecated_text1};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
//     text-decoration: none;
//   }
// `

export default function Header() {
  // const tokensFlag = useTokensFlag()

  const { account, chainId } = useWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const userMetanoBalance = useCurrencyBalance(account ?? undefined, METANO_MAINNET ?? undefined)

  const addtoMetamask = () => {
    addTokenToMetamask(
      METANO_MAINNET.address,
      METANO_MAINNET.symbol,
      METANO_MAINNET.decimals,
      'https://metano.org/images/metano.png'
    )
  }
  // const [darkMode] = useDarkModeManager()
  // const { deprecated_white, deprecated_black } = useTheme()

  // const toggleClaimModal = useToggleSelfClaimModal()

  // const availableClaim: boolean = useUserHasAvailableClaim(account)

  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  // const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  // const { pathname } = useLocation()

  const {
    // infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = getChainInfoOrDefault(chainId)

  // work around https://github.com/remix-run/react-router/issues/8161
  // as we can't pass function `({isActive}) => ''` to className with styled-components
  // const isPoolActive =
  //   pathname.startsWith('/pool') ||
  //   pathname.startsWith('/add') ||
  //   pathname.startsWith('/remove') ||
  //   pathname.startsWith('/increase') ||
  //   pathname.startsWith('/find')

  return (
    <HeaderFrame showBackground={scrollY > 45}>
      {/* <ClaimModal />
      <Title href=".">
        <UniIcon>
          <Logo fill={darkMode ? deprecated_white : deprecated_black} width="24px" height="100%" title="logo" />
          <HolidayOrnament />
        </UniIcon>
      </Title> */}
      {/* <HeaderLinks>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>Swap</Trans>
        </StyledNavLink>
        {tokensFlag === TokensVariant.Enabled && (
          <StyledNavLink id={`tokens-nav-link`} to={'/tokens'}>
            <Trans>Tokens</Trans>
          </StyledNavLink>
        )}
        <StyledNavLink
          data-cy="pool-nav-link"
          id={`pool-nav-link`}
          to={'/pool'}
          className={isPoolActive ? activeClassName : undefined}
        >
          <Trans>Pool</Trans>
        </StyledNavLink>
        {(!chainId || chainId === SupportedChainId.MAINNET) && (
          <StyledNavLink id={`vote-nav-link`} to={'/vote'}>
            <Trans>Vote</Trans>
          </StyledNavLink>
        )}
        <StyledExternalLink id={`charts-nav-link`} href={infoLink}>
          <Trans>Charts</Trans>
          <sup>↗</sup>
        </StyledExternalLink>
      </HeaderLinks> */}

      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          {/* {availableClaim && !showClaimPopup && (
          <UNIWrapper onClick={toggleClaimModal}> */}
          {/* <CardNoise />
            </UNIWrapper>
          )} */}
          <AccountElement active={!!account}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr=".4rem" fontWeight={500}>
                <Trans>
                  {userEthBalance?.toSignificant(3)} {nativeCurrencySymbol}
                </Trans>
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        {/* <HeaderElement>
          <Menu />
        </HeaderElement> */}
      </HeaderControls>
      <HeaderControls>
        {account && userMetanoBalance ? (
          <UNIAmount active={!!account && !userEthBalance} style={{ pointerEvents: 'auto' }} onClick={addtoMetamask}>
            <ThemedText.DeprecatedWhite padding="0 2px">
              {formatCurrencyAmount(userMetanoBalance, 4)} {METANO_MAINNET.symbol}
            </ThemedText.DeprecatedWhite>
          </UNIAmount>
        ) : null}
      </HeaderControls>
    </HeaderFrame>
  )
}
