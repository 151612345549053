// import { Trans } from '@lingui/macro'
import { MetamaskIcon } from 'components/Svg'
import { METANO_MAINNET } from 'constants/tokens'
// import { useLocationLinkProps } from 'hooks/useLocationLinkProps'
// import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { addTokenToMetamask } from 'utils/addToMetamask'

// import { DEFAULT_LOCALE, SupportedLocale } from '../../constants/locales'
// import { navigatorLocale, useActiveLocale } from '../../hooks/useActiveLocale'
import { ThemedText } from '../../theme'

const Container = styled(ThemedText.DeprecatedSmall)`
  opacity: ${({ theme }) => theme.opacity.hover};
  :hover {
    opacity: 1;
  }
  margin-top: 1rem !important;
`
const CustomButton = styled.button`
  background: transparent;
  cursor: pointer;
  border: 0;
`
const CustomDiv = styled.div`
  padding: 0.5rem 0;
  text-align: center;
`

// const useTargetLocale = (activeLocale: SupportedLocale) => {
//   const browserLocale = useMemo(() => navigatorLocale(), [])

//   if (browserLocale && (browserLocale !== DEFAULT_LOCALE || activeLocale !== DEFAULT_LOCALE)) {
//     if (activeLocale === browserLocale) {
//       return DEFAULT_LOCALE
//     } else {
//       return browserLocale
//     }
//   }
//   return null
// }

export function SwitchLocaleLink() {
  const iconProps = {
    width: '16px',
    ...('0px' && { ml: '0px' }),
  }

  // const activeLocale = useActiveLocale()
  // const targetLocale = useTargetLocale(activeLocale)

  // const { to } = useLocationLinkProps(targetLocale)

  const addtoMetamask = () => {
    addTokenToMetamask(
      METANO_MAINNET.address,
      METANO_MAINNET.symbol,
      METANO_MAINNET.decimals,
      'https://metano.org/images/metano.png'
    )
  }

  // if (!targetLocale || !to) return null

  return (
    <Container>
      <CustomDiv>
        Add METANO to Metamask{' '}
        <CustomButton onClick={addtoMetamask}>
          <MetamaskIcon {...iconProps} />
        </CustomButton>
      </CustomDiv>
      Powered by Uniswap, add Liquidity{' '}
      <a
        href={'https://app.uniswap.org/#/add/v2/ETH/0x9D9e399e5385e2b9A58d4F775A1E16441b571afb'}
        rel="noreferrer"
        target="_blank"
      >
        here
      </a>
    </Container>
  )
}
