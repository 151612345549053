const { ethereum } = window as any

export const addTokenToMetamask = (tokenAddress: any, tokenSymbol: any, tokenDecimals: any, tokenImage: any) => {
  if (ethereum) {
    ethereum
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      })
      .catch((error: any) => {
        if (error.code === 4001) {
          // EIP-1193 userRejectedRequest error
          console.log('We can encrypt anything without the key.')
        } else {
          console.error(error)
        }
      })
  }
}
